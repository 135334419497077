.main-header {
	
	@extend .grid-x;
	@extend .align-middle;
	padding: 1.5rem 2.5rem;
	position: fixed;
	z-index: 500;
		top: 0;
		left: 0;
	width: 100%;
	background: #fff;
	transition: all .3s ease-out;
	
	&.nav-up {
		transform: translateY(-105px);
	}
	&.nav-down {
		transform: translateY(0px);
	}
}

.main-nav {
	text-align: right;
	padding-right: 5rem;
	
	& > .link {
		padding-left: 2rem;
		transition: all .3s ease-out;
		
		&:hover {
			opacity: .5;
		}
	}
}

.main-logo {
	width: 350px;
}

.header-spacer {
	height: 104px;
}

@media only screen and (max-width: 640px) {
	
	.main-header {
		padding: 0px;
		text-align: center;
		
		& > .cell {
			padding: 1.5rem 2.5rem;
			background: #fff;
		}
		
		&.nav-up {
			transform: translateY(-105px);
			
			& > .main-nav {
				transform: translateY(-100%);
			}
		}
	}
	
	.carrot {
		width: 15px;
		margin: 0 auto;
		display: inline-block;
		margin-top: .5rem;
		transition: all .3s ease-out;
		
		&.-active {
			transform: rotate(180deg);
		}
	}
	
	.main-nav {
		padding: 0px;
		text-align: center;
		background: $light-grey !important;
		transform: translateY(-100%);
		position: absolute;
		z-index: 1;
			top: 100%;
			left: 0;
		width: 100%;
		transition: all .3s ease-out;
		
		&.-active {
			transform: translateY(0%);
		}
				
		& > .link {
			width: 100%;
			display: block;
			padding-left: 0px;
			padding-top: .5rem;
			padding-bottom: .5rem;
		}
	}
	
}