// Fonts
$global-font-size: 100%;
$global-width: rem-calc(1200);
$global-lineheight: 1.5;
$global-weight-normal: normal;
$global-weight-bold: bold;



// Colors
$navy: #102544;
$light-grey: #e6e6e6;
$medium-grey: #cacaca;
$dark-grey: #8a8a8a;
$black: #0a0a0a;
$white: #fefefe;

$colors: (
  navy: $navy,
  lightGrey: $light-grey,
	mediumGrey: $medium-grey,
	darkGrey: $dark-grey,
	black: $black,
	white: $white
);



//
// Easings
//
$in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);