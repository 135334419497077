@mixin font(
$style: normal,
$variant: normal,
$weight: 400,
$size: 1em,
$line-height: 1.2em,
$font: 'Steradian',
$color: $body-font-color) {
	
	font: $style $variant $weight #{$size}/#{$line-height} $font;
	color: $color;
	text-transform: none;
}