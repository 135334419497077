// Garamond
@font-face {
  font-family: 'Garamond';
  src: url('../fonts/AGaramondPro-Regular.otf');
  font-weight: 300;
  font-style: normal;
}

// Helvetica LT
@font-face {
  font-family: 'Helvetica Cond';
  src: url('../fonts/HelveticaLTStd-Cond.otf');
  font-weight: 400;
  font-style: normal;
}

// Helvetica Neue
@font-face {
  font-family: 'Helvetica';
  src: url('../fonts/HelveticaNeue.ttf');
  font-weight: 500;
  font-style: normal;
}
/*
		font(
			
		$style: normal,
		$variant: normal,
		$weight: 400,
		$size: 1em,
		$line-height: 1.2em,
		$font: "Gibson",
		$color: #000
		
		)
*/

h1,
h2,
h3,
h4,
h5,
h6 {
	@include font();
}