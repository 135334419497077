/*
		font(
			
		$style: normal,
		$variant: normal,
		$weight: 400,
		$size: 1em,
		$line-height: 1.2em,
		$font: "Gibson",
		$color: #000
		
		)
*/

.header-one {
	@include font(
		$font: 'Garamond',
		$size: 3.5rem,
		$line-height: 1em
	);
	color: $navy;
}

.header-two {
	@include font(
		$font: 'Garamond',
		$size: 3.3rem,
		$line-height: 1.3em
	);
	color: $navy;
}

.header-three {
	@include font(
		$font: 'Garamond',
		$size: 3rem,
		$line-height: 1em
	);
	color: $navy;
}

.header-four {
	@include font(
		$font: 'Garamond',
		$size: 2.1rem,
		$line-height: 1.3em
	);
	color: $navy;
}

.header-five {
	@include font(
		$font: 'Helvetica Cond',
		$size: 1.4rem,
		$line-height: 1.3em
	);
	color: $navy;
	text-transform: uppercase;
}

.copy-one {
	@include font(
		$font: 'Helvetica',
		$size: 1rem,
		$line-height: 1.8em
	);
	text-align: left;
}

@media screen and (min-width: 640px) {
	.header-one {
		font-size: 4.5rem;
	}
	.header-two {
		font-size: 3.8rem;
	}
	.header-three {
		font-size: 3.6rem;
	}
	.copy-one {
		text-align: justify;
	}
}