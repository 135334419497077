// Colors
.-color {
	@each $name, $color in $colors {
		&-#{$name} {
			color: $color;
		}
	}
}



// Styles

.-thicc,
strong,
.-bold {
	font-weight: 500;
}

.-med {
	font-weight: 400;
}

.-uppercase {
	text-transform: uppercase;
}

.-boxed {
	display: inline-block;
	background: $navy;
	padding: 1rem;
	padding-left: 12vw;
	margin-left: -12vw;
}

.-garamond {
	font-family: "Garamond";
	text-transform: none;
}

@media screen and (min-width: 640px) {
	.-two-columns {
		column-count: 2;
		column-gap: 3rem;
	}
}