.flex-layout {
	
	@extend .grid-x;
	
	& > .content {
		
		padding: 5rem 10vw;
		
		&.-right {
			padding: 5rem 10vw 5rem 5rem;
		}
		&.-left {
			padding: 5rem 5rem 5rem 10vw;
		}
		&.-narrow {
			padding: 5rem 10vw;
		}
	}
	
}

.overlap-card {
	
	padding: 0rem 0vw;
	position: relative;
	
	&:before {
		position: absolute;
		display: block;
		content: "";
			top: 0;
			left: 0;
		width: 100%;
		height: 50%;
		background: $light-grey;
		z-index: -1;
	}
	
	& > .card {
		padding: 5rem 20vw;
	}
	
}

.team-grid {
	
	padding: 5rem 10vw;
	
	& > .content {
		padding: 5rem 10vw;
	}
}

.team-member {
	@extend .grid-x;
	@extend .align-justify;
	@extend .align-middle;
	padding-top: 3rem;
	padding-bottom: 3rem;
	
	.headshot {
		width: 100%;
		padding-bottom: 100%;
		background-size: cover;
		background-position: center center;
		margin-bottom: 3rem;
	}
	& > .info {
		@extend .cell;
		width: 100%;
	}
	
	&.-alt {
		.headshot {
			order: 1;
		}
		& > .info {
			order: 2;
		}
	}
}

.headshot {
	@extend .small-12;
	@extend .medium-6;
	@extend .cell;
	padding-bottom: 50%;
}

.press-grid {
	@extend .grid-x;
	padding: 5rem 10vw;
	padding-top: 0rem;
}

.image-square {
	width: 100%;
	padding-bottom: 100%;
}


.contact-form {
	max-width: 800px;
	margin: 0 auto;
}

@media screen and (min-width: 640px) {
	
	.contact-form-container {
		padding: 5rem 8vw;
	}
	
	.team-grid {
	
		padding: 5rem 17vw;
		
		& > .content {
			padding: 5rem 12vw;
		}
	}
	
	.contact-form {
		padding: 0rem 0rem;
	}
	
	.flex-layout {
		
		& > .content {
			
			padding: 5rem 12vw;
			
			&.-right {
				padding: 5rem 12vw 5rem 5rem;
			}
			&.-left {
				padding: 5rem 5rem 5rem 12vw;
			}
			&.-narrow {
				padding: 5rem 17vw;
			}
		}
		
	}
	
	.overlap-card {
		padding: 0rem 10vw;
		
		& > .card {
			padding: 5rem 10vw;
		}
	}
	
	.press-grid {
		padding: 5rem 10vw;
		padding-top: 5rem;
	}
	
}

@media screen and (min-width: 1026px) {
	
	.team-member {
		
		& > .info {
			width: 45%;
		}
		
		&.-alt {
			.headshot {
				order: 2;
				margin-bottom: 0rem;
			}
			& > .info {
				order: 1;
			}
		}
	}
	
	.press-grid {
		padding: 5rem 15vw;
	}
	
	.overlap-card {
		& > .card {
			padding: 5rem 20vw;
		}
	}
	
}