/*
		font(
			
		$style: normal,
		$variant: normal,
		$weight: 400,
		$size: 1em,
		$line-height: 1.2em,
		$font: "Gibson",
		$color: #000
		
		)
*/


//
//
// Link
//
//

.link {
	
	@include font(
		$font: 'Helvetica Cond',
		$size: 1rem,
		$line-height: 1em
	);
	
	text-transform: uppercase;
	color: $navy;
	
}

.button {
	@include font(
		$font: 'Helvetica Cond',
		$size: 1rem,
		$line-height: 1rem
	);
	border: 1px solid $navy;
	padding: .7rem 1.2rem;
	padding-top: 1rem;
	text-transform: uppercase;
	display: inline-block;
	transition: all .3s ease-out;
	
	&.-white {
		border-color: #fff;
		color: #fff;
	}
	
	&:hover {
		background: $navy;
		color: #fff;
	}
}

input[type="radio"] {
	
	transform: translateX(-9999px);
	
}
.input-label {
	
	@include font(
		$font: 'Helvetica Cond',
		$size: 1.2rem,
		$line-height: 1rem
	);
	color: $navy;
	text-transform: uppercase;
	position: relative;
	padding-left: 2rem;
	padding-top: .4rem;
	cursor: pointer;
	
	&:before {
		display: inline-block;
		content: "";
		position: absolute;
			top: 50%;
			left: 0;
		transform: translateY(-50%);
		height: 20px;
		width: 20px;
		border-radius: 1000px;
		border: 1px solid $navy;
		background: transparent;
		transition: all .4s ease-out;
	}
	
	
	&:hover {
		&:before {
			background: #aaa;
		}
	}
}

input[type="radio"]:checked + .input-label {
	&:before {
		background: $navy;
	}
}

input[type="text"],
input[type="email"],
textarea {
	
	@include font(
		$font: 'Helvetica',
		$size: 1rem,
		$line-height: 1.8em
	);
	
	width: 100%;
	background: #f2f2f2;
	appearance: none;
	outline: none;
	border: none;
	box-shadow: none;
	padding: .8rem 1.2rem;
	color: #444;
	border-radius: 0px;
	
	&::Placeholder {
		color: $medium-grey;
	}
}

.sm-link {
	color: #fff;
	font-size: 2.6rem;
	transition: all .4s ease-out;
	
	&:hover {
		color: #fff;
		opacity: .7;
	}
}