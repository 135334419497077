.main-footer {
	background: $navy;
	padding: 1.6rem 8vw;
	@extend .grid-x;
	position: relative;
	
	a {
		color: #fff;
		transition: all .3s ease-out;
		
		&:hover {
			opacity: .7;
		}
	}
}

.footer-logo {
	height: 3.1rem;
}

@media only screen and (max-width: 640px) {
	.main-footer {
		.copy-one {
			font-size: .9rem;
			text-align: center;
			margin-top: .5rem;
		}
	}
}